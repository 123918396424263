var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"二级考点","visible":_vm.dialogVisible,"close-on-click-modal":false,"append-to-body":true,"width":"1200rem","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-table',{ref:"theTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":"520rem","tree-props":{child: 'child'},"row-key":"id"},on:{"row-click":_vm.getOpenDetail}},[_c('el-table-column',{attrs:{"prop":"text","align":"left","label":"考点"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(row.knowledgeDesc?row.knowledgeDesc:row.check_point_3_desc)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"rate","align":"left","label":" 得分率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(row.rate)+" ")])]}}])}),(_vm.type == 9)?_c('el-table-column',{attrs:{"prop":"do_question_count","align":"left","label":" 训练次数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(row.doQuestionCount)+" ")])]}}],null,false,2834253657)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"text","align":"left","label":"易错指数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"checkpoint"},[_vm._v(" "+_vm._s(row.errorProne)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.videoCount>0&&_vm.userInfo.show_video!=0)?_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.showVideo(row)}}},[_vm._v(" 考点讲解 ")]):_vm._e(),(row.lecture_data.length>0)?_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.viewLecture(row)}}},[_vm._v(" 查看讲义 ")]):_vm._e(),_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.doPaper(row)}}},[_vm._v(" 开始训练 ")])]}}])})],1)],1),_c('LectureForm',{ref:"LectureFormRef"}),_c('VideoForm',{ref:"VideoFormRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }